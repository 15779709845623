export class Player {
    private id: UniqueId;
    private name: string;
    private surname: string;
    private level: string;
    private createdAt: Date;
    private modifiedAt: Date;

    constructor(id: UniqueId, name: string, surname: string, level: string, createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.name = name;
        this.surname = surname;
        this.level = level;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    public Id = (): UniqueId => {
        return this.id
    }

    public Name = (): string => {
        return this.name
    }

    public Surname = (): string => {
        return this.surname
    }

    public Level = (): string => {
        return this.level
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }

}