import { useContext, useEffect } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { Menu2 } from "tabler-icons-react"

export const TournamentList = () => {
    let { id } = useParams();
    let useCases = useContext(UseCasesContext)

    const tournaments = useTypedSelector(({ tournament }) => {
        return tournament
    })

    useEffect(() => {
        useCases?.tournamentUseCase.ReadTournaments()
    }, [])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                            <a className="nav-item nav-link px-0 me-xl-4" onClick={(e)=> {e.preventDefault(); document.querySelector('html')?.classList.add('layout-menu-expanded')}}>
                                <i className="ti ti-menu-2 ti-sm"></i>
                                <Menu2 />
                            </a>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список турниров /</span> Турниры</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/tournament/add/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Добавить турнир</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="table-responsive text-nowrap">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th>Название</th>
                                            <th>Дата начала</th>
                                            <th>Дата создания</th>
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            tournaments?.tournaments && tournaments.tournaments.map((tournament, index) => {
                                                return <TableRow tournament={tournament} last={tournaments?.tournaments!.length - 1 == index ? true : false} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}