import axios from "axios";
import { Configuration, GamesApi } from "../generated";
import { GameInterface } from "../../../useCase/game/adapters/api/Interface";
import { Game, Score, Set } from "../../../domain/game/game";

export class GamesRepository implements GameInterface {
    private service: GamesApi;

    constructor() {
        this.service = new GamesApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadGamesOfLastTournament(): Promise<Game[] | Error> {
        try {
            let response = await this.service.apiGameGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let games: Game[] = []
                response.data.forEach(gameData => {
                    let gameDate = new Date(gameData.gameDay!)
                    let startDate = new Date(gameData.startDate!)
                    let deadline = new Date(gameData.deadline!)
                    let createdAt = new Date(gameData.createdAt!)
                    let modifiedAt = new Date(gameData.modifiedAt!)
                    let score = new Score([]);
                    gameData.score?.sets?.forEach((set) => {
                        score.AddSet(new Set(set.playerAScore!, set.playerBScore!));
                    })


                    let game = new Game(gameData.id!, gameData.groupId!, gameData.tour!, gameData.playerA!, gameData.playerB!, gameDate, score, gameData.winner!, startDate, deadline, createdAt, modifiedAt)
                    games.push(game)
                });
                return games
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadGameById(id: UniqueId): Promise<Game | Error> {
        try {
            let response = await this.service.apiGameIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {

                let gameDate = new Date(response.data.gameDay!)
                let startDate = new Date(response.data.startDate!)
                let deadline = new Date(response.data.deadline!)
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let score = new Score([]);
                response.data.score?.sets?.forEach((set) => {
                    score.AddSet(new Set(set.playerAScore!, set.playerBScore!));
                })


                let game = new Game(response.data.id!, response.data.groupId!, response.data.tour!, response.data.playerA!, response.data.playerB!, gameDate, score, response.data.winner!, startDate, deadline, createdAt, modifiedAt)
                return game

            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async SetScoreRequest(id: UniqueId, date: Date, score: Score): Promise<Game | Error> {
        try {
            let sets: Array<{ playerAScore: number, playerBScore: number }> = []
            score.Sets().forEach((set) => {
                sets.push({
                    playerAScore: set.PlayerAScore(),
                    playerBScore: set.PlayerBScore(),
                })
            })
            let scoreParam = {
                played_date: date.toISOString(),
                score: {
                    sets: sets,
                },
            }
            let response = await this.service.apiGameIdPut(id, scoreParam, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let gameDate = new Date(response.data.gameDay!)
                let startDate = new Date(response.data.startDate!)
                let deadline = new Date(response.data.deadline!)
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let score = new Score([]);
                response.data.score?.sets?.forEach((set) => {
                    score.AddSet(new Set(set.playerAScore!, set.playerBScore!));
                })


                let game = new Game(response.data.id!, response.data.groupId!, response.data.tour!, response.data.playerA!, response.data.playerB!, gameDate, score, response.data.winner!, startDate, deadline, createdAt, modifiedAt)
                return game
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}