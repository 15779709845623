import { Table } from "../../domain/table/table";
import { TablesRepository } from "../../repository/api/table/table";
import { actionCreators } from "../../state";

export class TableUseCases {
    private api: TablesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: TablesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadTablesOfLastTournament = async () => {
        this.stateActions.SendTableListRequest()
        let response = await this.api.ReadTablesOfLastTournament()
        if (response instanceof Error) {
            this.stateActions.TableListError(response.message)
            return
        } else {
            this.stateActions.TableListSuccess(response)
        }
    }
}