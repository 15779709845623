import { Table } from "../../domain/table/table";
import { TableActionTypes } from "../action-types";
import { TableActions } from "../actions";
import produce from 'immer';


interface TableState {
    loading: boolean | null;
    tables: Table[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: TableState = {
    loading: null,
    tables: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: TableState = initialState, action: TableActions) => {
    switch (action.type) {
        case TableActionTypes.TABLE_REQUEST_SEND:
            state.loading = true;
            return state;
        case TableActionTypes.TABLE_SUCCESS:
            state.loading = false;
            state.tables = action.payload;
            return state;
        case TableActionTypes.TABLE_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
