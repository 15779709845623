import { Group } from "../../domain/group/group";
import { GroupActionTypes } from "../action-types";
import { GroupActions } from "../actions";
import produce from 'immer';


interface GroupState {
    loading: boolean | null;
    groups: Group[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: GroupState = {
    loading: null,
    groups: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: GroupState = initialState, action: GroupActions) => {
    switch (action.type) {
        case GroupActionTypes.GROUP_REQUEST_SEND:
            state.loading = true;
            return state;
        case GroupActionTypes.GROUP_SUCCESS:
            state.loading = false;
            state.groups = action.payload;
            return state;
        case GroupActionTypes.GROUP_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
