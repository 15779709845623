import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Player } from "../../../../domain/player/player"
import { ChevronRight, Menu2, Trash } from "tabler-icons-react"

export const TournamentAddPlayer = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [freePlayers, setFreePlayers] = useState<{title: string, value: string}[]>([])

    let useCases = useContext(UseCasesContext)
    
    const [form, setForm] = useState<{
        playerId: UniqueId,
    }>({
        playerId: "",
    })



    const submit = () => {

        if (form.playerId !== "" && id) {
            useCases?.tournamentUseCase.AddPlayerToTournament(id!, form.playerId!,  () => { navigate(-1) })
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readFreePlayersForTournament = async (id: UniqueId) => {
        let response = await useCases?.tournamentUseCase.ReadFreePlayersOfTournament(id)
        if (response instanceof Error) {

        } else {
            let playersOptions: {title: string, value: string}[] = []
            response?.forEach((player)=>{
                playersOptions.push({title: player.Name()+ " "+ player.Surname()+" ("+levels.get(player.Level())+")", value: player.Id()})
            })
            setFreePlayers(playersOptions)
        }
    }

    const players = useTypedSelector(({ player }) => {
        return player
    })


    useEffect(() => {
        if (id) {
            readFreePlayersForTournament(id)
        }
    }, [id])

    const levels = new Map<string, string>([
        ["junior", "Новичок"],
        ["middle", "Средний"],
        ["senior", "Топ"],
    ])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                                <a className="nav-item nav-link px-0 me-xl-4" onClick={(e) => { e.preventDefault(); document.querySelector('html')?.classList.add('layout-menu-expanded') }}>
                                    <i className="ti ti-menu-2 ti-sm"></i>
                                    <Menu2 />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Редактирование турнира /</span> Добавить игрока</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление игрока по ходу турнира</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "playerId",
                                            title: "Игрок",
                                            placeholder: "Выберите игрока для добавления",
                                            type: FiledType.Select,
                                            value: form.playerId,
                                            options: freePlayers,
                                        },

                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={""}
                                    updateForm={updateForm}
                                />
                            </div>
                            <div className="d-flex justify-content-end" style={{ marginBottom: "30px", marginRight: "20px" }} onClick={(e) => { e.preventDefault(); submit() }}>
                                <button className="btn btn-primary d-grid">Сохранить</button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
