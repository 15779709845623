import { useContext, useState } from "react";
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { Game, Score } from "../../../../domain/game/game";
import { Player } from "../../../../domain/player/player";
import { Group } from "../../../../domain/group/group";

export const TableRow = (props: { game: Game, player1: Player | null, player2: Player | null, group: Group | null, last: boolean, edit:boolean }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }

    const scoreToText = (score: Score): string => {
        let text = ""
        score.Sets().forEach((set)=>{
            if (text !== "") {
                text += " "
            }
            text += set.PlayerAScore()+":"+set.PlayerBScore()
        })
        return text
    }

    const levels = new Map<string, string>([
        ["junior", "Новичок"],
        ["middle", "Средний"],
        ["senior", "Топ"],
    ])
    return (<tr>
        <td>{props.group?.Name()}</td>
        <td>{props.game?.Tour()}</td>
        <td>{props.player1?.Name()+" "+props.player1?.Surname()}</td>
        <td>{scoreToText(props.game!.Score())}</td>
        <td>{props.player2?.Name()+" "+props.player2?.Surname()}</td>
        <td>{props.game.GameDay().getFullYear() === 1 ? formatDate(props.game.StartDate())+" - "+formatDate(props.game.Deadline()): formatDate(props.game.GameDay())}</td>
        {props.edit?<td>
            <div className="dropdown">
                <Link to={"/game/score/"+props.game.Id()} onClick={()=>{setShowMenu(!showMenu)}} className="btn btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
                    Установить счет
                </Link>
            </div>
        </td>:<></>}
    </tr>)
}
