import { useContext, useState } from "react";
import {  Tournament } from "../../../../domain/tournament/tournament";
import { DotsVertical, Edit, Trash, UserExclamation } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { UserCheck } from "react-feather";

export const TableRow = (props: { tournament: Tournament, last: boolean }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }

    return (<tr>
        <td>{props.tournament.Name()}</td>
        <td>{formatDate(props.tournament.StartDate())}</td>
        <td>{formatDate(props.tournament.CreatedAt())}</td>
        <td>
            <div className="dropdown">
                <button type="button" onClick={()=>{setShowMenu(!showMenu)}} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <DotsVertical />
                </button>
                <div className={showMenu?"dropdown-menu show":"dropdown-menu "} style={showMenu ? props.last ? { position: "absolute", inset: "auto auto 0px -20%", margin: "0px", transform: "translate3d(0px, -20.5px, 0px)" }: {position: "absolute", marginLeft: "-40%",}: {display:"none"}}>
                    <Link to={"/tournament/add-player/"+props.tournament.Id()} className="dropdown-item d-flex"><UserExclamation className="me-1" size={20} /> Добавить игрока</Link>
                    <a className="dropdown-item d-flex" href="#" onClick={(e)=>{e.preventDefault(); useCases?.tournamentUseCase.DeleteTournament(props.tournament.Id()); setShowMenu(false)}}><Trash className="me-1" size={20} /> Удалить</a>
                </div>
            </div>
        </td>
    </tr>)
}
