import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import playerReducer from './playerReducer';
import tournamentReducer from './tournamentReducer';
import gameReducer from './gameReducer';
import groupReducer from './groupReducer';
import tableReducer from './tableReducer';

const reducers = combineReducers({
    login: loginReducer,
    player: playerReducer,
    tournament: tournamentReducer,
    game: gameReducer,
    group: groupReducer,
    table: tableReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>;