import React, { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../context/useCases"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { TableRow } from "./tableRow"
import { Menu } from "../../components/menu"
import { Menu2 } from "tabler-icons-react"
import "./font.css"

export const TablesPage = ({showMenu}:{showMenu: boolean}) => {
    let useCases = useContext(UseCasesContext)

    const tables = useTypedSelector(({ table }) => {
        return table
    })


    useEffect(() => {
        useCases?.tableUseCase.ReadTablesOfLastTournament()
    }, [])


    return (
        <div className={showMenu?"layout-page":""} >
            {showMenu?<Menu />:<></>}
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        {showMenu?<div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                            <a className="nav-item nav-link px-0 me-xl-4" onClick={(e)=> {e.preventDefault(); document.querySelector('html')?.classList.add('layout-menu-expanded')}}>
                                <i className="ti ti-menu-2 ti-sm"></i>
                                <Menu2 />
                            </a>
                        </div>:""}
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Турнирные таблицы /</span> Таблицы</h4>
                        </div>

                        {tables?.tables?.map((table, index) => {
                            return <React.Fragment>
                                <h5>{table.GroupName()}</h5>
                                <div className="card" style={{marginBottom: "40px"}}>
                                    <div className="text-nowrap">
                                        <table className="table table-responsive mt-1">
                                            <thead>
                                                <tr className="d-flex align-items-center">
                                                    <th scope="row" className="col-1">М</th>
                                                    <th className="col-4 col-lg">Игрок</th>
                                                    <th className="col-1">И</th>
                                                    <th className="col-1">В</th>
                                                    <th className="col-1">П</th>
                                                    <th className="col-1">О</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table mt-1 table-responsive table-hover">
                                                {
                                                    table?.Items().map((tableItem, index) => {
                                                        return <TableRow tableItem={tableItem}  last={table?.Items()!.length - 1 == index ? true : false} />
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </React.Fragment>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )

}