import { Tournament } from "../../domain/tournament/tournament";
import { TournamentActionTypes } from "../action-types";
import { TournamentActions } from "../actions";
import produce from 'immer';


interface TournamentState {
    loading: boolean | null;
    tournaments: Tournament[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: TournamentState = {
    loading: null,
    tournaments: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: TournamentState = initialState, action: TournamentActions) => {
    switch (action.type) {
        case TournamentActionTypes.TOURNAMENT_REQUEST_SEND:
            state.loading = true;
            return state;
        case TournamentActionTypes.TOURNAMENT_SUCCESS:
            state.loading = false;
            state.tournaments = action.payload;
            return state;
        case TournamentActionTypes.TOURNAMENT_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case TournamentActionTypes.TOURNAMENT_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case TournamentActionTypes.TOURNAMENT_CREATE_SUCCESS:
            state.entityLoading = false
            state.tournaments!.push(action.payload)
            state.entityError = ""
            return state;
        case TournamentActionTypes.TOURNAMENT_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case TournamentActionTypes.TOURNAMENT_DELETE_SUCCESS:
            state.tournaments = state.tournaments!.filter((tournaments)=>tournaments.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
