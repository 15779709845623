export class Table {
    private tournamentId: UniqueId;
    private tournamentName: string;
    private groupId: UniqueId;
    private groupName: string;
    private items: TableItem[];

    constructor(tournamentId: UniqueId, tournamentName: string, groupId: UniqueId, groupName: string, items: TableItem[]) {
        this.tournamentId = tournamentId;
        this.tournamentName = tournamentName;
        this.groupId = groupId;
        this.groupName = groupName;
        this.items = items;
    }

    public TournamentId = (): UniqueId => {
        return this.tournamentId
    }

    public TournamentName = (): string => {
        return this.tournamentName
    }
    
    public GroupId = (): UniqueId => {
        return this.groupId
    }

    public GroupName = (): string => {
        return this.groupName
    }

    public Items = (): TableItem[] => {
        return this.items
    }

}



export class TableItem {
    private playerId: UniqueId;
    private place: number;
    private name: string;
    private surname: string;
    private level: string;
    private playedGames: number;
    private wins: number; 
    private loses: number;
    private points: number;


    constructor(playerId: UniqueId, place: number, name: string, surname: string, level: string, playedGames: number, wins: number, loses: number, points: number) {
        this.playerId = playerId;
        this.place = place;
        this.name = name;
        this.surname = surname;
        this.surname = surname;
        this.level = level;
        this.playedGames = playedGames;
        this.wins = wins;
        this.loses = loses;
        this.points = points;;
    }

    public PlayerId = (): UniqueId => {
        return this.playerId
    }

    public Place = (): number => {
        return this.place
    }
    
    public Name = (): string => {
        return this.name
    }

    public Surname = (): string => {
        return this.surname
    }

    public Level = (): string => {
        return this.level
    }

    public PlayedGames = (): number => {
        return this.playedGames
    }

    public Wins = (): number => {
        return this.wins
    }

    public Loses = (): number => {
        return this.loses
    }

    public Points = (): number => {
        return this.points
    }
}

