import axios from "axios";
import { Configuration, GroupsApi } from "../generated";
import { GroupInterface } from "../../../useCase/group/adapters/api/Interface";
import { Player } from "../../../domain/player/player";
import { Group } from "../../../domain/group/group";

export class GroupsRepository implements GroupInterface {
    private service: GroupsApi;

    constructor () {
        this.service = new GroupsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async  ReadGroupsOfLastTournament(): Promise<Group[] | Error> {
        try {
            let response = await this.service.apiGroupGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let groups:Group[] = []
                response.data.forEach(groupData => {
                    let createdAt = new Date(groupData.createdAt!)
                    let modifiedAt = new Date(groupData.modifiedAt!)
                    let group = new Group(groupData.id!, groupData.tournament_id!, groupData.name!, groupData.players!, createdAt, modifiedAt)
                    groups.push(group)
                });
               return groups
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
}