import { Game } from "../../domain/game/game";
import { GameActionTypes } from "../action-types";
import { GameActions } from "../actions";
import produce from 'immer';


interface GameState {
    loading: boolean | null;
    games: Game[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: GameState = {
    loading: null,
    games: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: GameState = initialState, action: GameActions) => {
    switch (action.type) {
        case GameActionTypes.GAME_REQUEST_SEND:
            state.loading = true;
            return state;
        case GameActionTypes.GAME_SUCCESS:
            state.loading = false;
            state.games = action.payload;
            return state;
        case GameActionTypes.GAME_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
