import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../../pages/auth/login/login";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useContext, useEffect } from "react";
import { UseCasesContext } from "../../../context/useCases";
import { PlayerList } from "../../pages/players/list";
import { PlayerAdd } from "../../pages/players/add";
import { TournamentAdd } from "../../pages/tournaments/add";
import { TournamentList } from "../../pages/tournaments/list";
import { GameList } from "../../pages/games/list";
import { TablesPage } from "../../pages/tables";
import { GameScore } from "../../pages/games/score";
import { MainPage } from "../../pages/main-page";
import { TournamentAddPlayer } from "../../pages/tournaments/add-player";

export const RouterComponent = () => {
  let useCases = useContext(UseCasesContext)

  const login = useTypedSelector(({ login }) => {
    return login
  })

  useEffect(() => {
    useCases?.authUseCase.CheckAuthorization()
    setInterval(()=>{
      useCases?.authUseCase.CheckAuthorization()
    },10000)
  }, [])
 
  
  return (<Router basename="/">
    <Routes>
      {login?.authorized ? <>
        <Route path="/tables" element={<TablesPage showMenu={true} />} />

        <Route path="/game/score/:id" element={<GameScore />} />
        <Route path="/game" element={<GameList showMenu={true} />} />

        <Route path="/tournament/edit/:id" element={<TournamentAdd />} />
        <Route path="/tournament/add-player/:id" element={<TournamentAddPlayer />} />
        <Route path="/tournament/add/" element={<TournamentAdd />} />
        <Route path="/tournament" element={<TournamentList />} />


        <Route path="/player/edit/:id" element={<PlayerAdd />} />
        <Route path="/player/add" element={<PlayerAdd />} />
        <Route path="/player" element={<PlayerList />} />
        <Route path="*" element={<TournamentList />} />
      </> : <>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<MainPage />} />
      </>}
    </Routes>
  </Router>
  )
}