import { Dispatch } from "react"
import { GroupActionTypes } from "../action-types";
import { GroupActions } from "../actions"
import { Group } from "../../domain/group/group";

export const SendGroupListRequest = () => {
    return async (dispatch: Dispatch<GroupActions>) => {
        dispatch({
            type: GroupActionTypes.GROUP_REQUEST_SEND,
          });
    }
}

export const GroupListSuccess = (kvizes: Group[]) => {
    return async (dispatch: Dispatch<GroupActions>) => {
        dispatch({
            type: GroupActionTypes.GROUP_SUCCESS,
            payload: kvizes,
        });
    }
}

export const GroupListError = (error:string) => {
    return async (dispatch: Dispatch<GroupActions>) => {
        dispatch({
            type: GroupActionTypes.GROUP_ERROR,
            payload: error,
        });
    }
}


export const GroupUpdateRequest = () => {
    return async (dispatch: Dispatch<GroupActions>) => {
        dispatch({
            type: GroupActionTypes.GROUP_UPDATE_REQUEST,
          });
    }
}

export const GroupUpdateSuccess = (category: Group) => {
    return async (dispatch: Dispatch<GroupActions>) => {
        dispatch({
            type: GroupActionTypes.GROUP_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const GroupUpdateError = (message: string) => {
    return async (dispatch: Dispatch<GroupActions>) => {
        dispatch({
            type: GroupActionTypes.GROUP_UPDATE_ERROR,
            payload: message,
          });
    }
}