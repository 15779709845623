/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface BackendInternalDeliveryAuthSignInResponse
 */
export interface BackendInternalDeliveryAuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof BackendInternalDeliveryAuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendInternalDeliveryAuthSignInResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface BackendInternalDeliveryTablesTableItem
 */
export interface BackendInternalDeliveryTablesTableItem {
    /**
     * 
     * @type {string}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'level'?: string;
    /**
     * 
     * @type {number}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'loses'?: number;
    /**
     * 
     * @type {string}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'place'?: number;
    /**
     * 
     * @type {number}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'playedGames'?: number;
    /**
     * 
     * @type {string}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'player_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'points'?: number;
    /**
     * 
     * @type {string}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'surname'?: string;
    /**
     * 
     * @type {number}
     * @memberof BackendInternalDeliveryTablesTableItem
     */
    'wins'?: number;
}
/**
 * 
 * @export
 * @interface DeliveryErrorResponse
 */
export interface DeliveryErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof DeliveryErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GameGameResponse
 */
export interface GameGameResponse {
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'gameDay'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'playerA'?: string;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'playerB'?: string;
    /**
     * 
     * @type {GameScore}
     * @memberof GameGameResponse
     */
    'score'?: GameScore;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GameGameResponse
     */
    'tour'?: number;
    /**
     * 
     * @type {string}
     * @memberof GameGameResponse
     */
    'winner'?: string;
}
/**
 * 
 * @export
 * @interface GameScore
 */
export interface GameScore {
    /**
     * 
     * @type {Array<GameSet>}
     * @memberof GameScore
     */
    'sets'?: Array<GameSet>;
}
/**
 * 
 * @export
 * @interface GameSet
 */
export interface GameSet {
    /**
     * 
     * @type {number}
     * @memberof GameSet
     */
    'playerAScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof GameSet
     */
    'playerBScore'?: number;
}
/**
 * 
 * @export
 * @interface GameSetGameScoreRequest
 */
export interface GameSetGameScoreRequest {
    /**
     * 
     * @type {string}
     * @memberof GameSetGameScoreRequest
     */
    'played_date'?: string;
    /**
     * 
     * @type {GameScore}
     * @memberof GameSetGameScoreRequest
     */
    'score'?: GameScore;
}
/**
 * 
 * @export
 * @interface GroupGroupResponse
 */
export interface GroupGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof GroupGroupResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupGroupResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupGroupResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupGroupResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupGroupResponse
     */
    'players'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GroupGroupResponse
     */
    'tournament_id'?: string;
}
/**
 * 
 * @export
 * @interface PlayerCreatePlayerRequest
 */
export interface PlayerCreatePlayerRequest {
    /**
     * 
     * @type {string}
     * @memberof PlayerCreatePlayerRequest
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerCreatePlayerRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerCreatePlayerRequest
     */
    'surname'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PlayerLevel = {
    Senior: 'senior',
    Middle: 'middle',
    Junior: 'junior'
} as const;

export type PlayerLevel = typeof PlayerLevel[keyof typeof PlayerLevel];


/**
 * 
 * @export
 * @interface PlayerPlayerResponse
 */
export interface PlayerPlayerResponse {
    /**
     * 
     * @type {string}
     * @memberof PlayerPlayerResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerPlayerResponse
     */
    'id'?: string;
    /**
     * 
     * @type {PlayerLevel}
     * @memberof PlayerPlayerResponse
     */
    'level'?: PlayerLevel;
    /**
     * 
     * @type {string}
     * @memberof PlayerPlayerResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerPlayerResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerPlayerResponse
     */
    'surname'?: string;
}
/**
 * 
 * @export
 * @interface PlayerUpdatePlayerRequest
 */
export interface PlayerUpdatePlayerRequest {
    /**
     * 
     * @type {string}
     * @memberof PlayerUpdatePlayerRequest
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerUpdatePlayerRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerUpdatePlayerRequest
     */
    'surname'?: string;
}
/**
 * 
 * @export
 * @interface TablesTableResponse
 */
export interface TablesTableResponse {
    /**
     * 
     * @type {string}
     * @memberof TablesTableResponse
     */
    'group_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablesTableResponse
     */
    'group_name'?: string;
    /**
     * 
     * @type {Array<BackendInternalDeliveryTablesTableItem>}
     * @memberof TablesTableResponse
     */
    'items'?: Array<BackendInternalDeliveryTablesTableItem>;
    /**
     * 
     * @type {string}
     * @memberof TablesTableResponse
     */
    'tournament_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TablesTableResponse
     */
    'tournament_name'?: string;
}
/**
 * 
 * @export
 * @interface TournamentAddPlayerToTournamentRequest
 */
export interface TournamentAddPlayerToTournamentRequest {
    /**
     * 
     * @type {string}
     * @memberof TournamentAddPlayerToTournamentRequest
     */
    'playerId'?: string;
}
/**
 * 
 * @export
 * @interface TournamentCreateTournamentRequest
 */
export interface TournamentCreateTournamentRequest {
    /**
     * 
     * @type {string}
     * @memberof TournamentCreateTournamentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TournamentCreateTournamentRequest
     */
    'players'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TournamentCreateTournamentRequest
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface TournamentTournamentResponse
 */
export interface TournamentTournamentResponse {
    /**
     * 
     * @type {string}
     * @memberof TournamentTournamentResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentTournamentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentTournamentResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentTournamentResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentTournamentResponse
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface TournamentUpdateTournamentRequest
 */
export interface TournamentUpdateTournamentRequest {
    /**
     * 
     * @type {string}
     * @memberof TournamentUpdateTournamentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TournamentUpdateTournamentRequest
     */
    'players'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TournamentUpdateTournamentRequest
     */
    'startDate'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiSignInPost', 'user', user)
            const localVarPath = `/api/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackendInternalDeliveryAuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<BackendInternalDeliveryAuthSignInResponse> {
            return localVarFp.apiSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GamesApi - axios parameter creator
 * @export
 */
export const GamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить всех игр текущего турнира.
         * @summary получить список всех игр текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGameGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить гейм по id.
         * @summary получить гейм по id.
         * @param {string} id Идентификатор гейма
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGameIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGameIdGet', 'id', id)
            const localVarPath = `/api/game/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * установить счет матча.
         * @summary установить счет матча.
         * @param {string} id Идентификатор гейма
         * @param {GameSetGameScoreRequest} score Счет игра
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGameIdPut: async (id: string, score: GameSetGameScoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGameIdPut', 'id', id)
            // verify required parameter 'score' is not null or undefined
            assertParamExists('apiGameIdPut', 'score', score)
            const localVarPath = `/api/game/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(score, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GamesApi - functional programming interface
 * @export
 */
export const GamesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GamesApiAxiosParamCreator(configuration)
    return {
        /**
         * получить всех игр текущего турнира.
         * @summary получить список всех игр текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGameGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GameGameResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGameGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить гейм по id.
         * @summary получить гейм по id.
         * @param {string} id Идентификатор гейма
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGameIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameGameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGameIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * установить счет матча.
         * @summary установить счет матча.
         * @param {string} id Идентификатор гейма
         * @param {GameSetGameScoreRequest} score Счет игра
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGameIdPut(id: string, score: GameSetGameScoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameGameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGameIdPut(id, score, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GamesApi - factory interface
 * @export
 */
export const GamesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GamesApiFp(configuration)
    return {
        /**
         * получить всех игр текущего турнира.
         * @summary получить список всех игр текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGameGet(options?: any): AxiosPromise<Array<GameGameResponse>> {
            return localVarFp.apiGameGet(options).then((request) => request(axios, basePath));
        },
        /**
         * получить гейм по id.
         * @summary получить гейм по id.
         * @param {string} id Идентификатор гейма
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGameIdGet(id: string, options?: any): AxiosPromise<GameGameResponse> {
            return localVarFp.apiGameIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * установить счет матча.
         * @summary установить счет матча.
         * @param {string} id Идентификатор гейма
         * @param {GameSetGameScoreRequest} score Счет игра
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGameIdPut(id: string, score: GameSetGameScoreRequest, options?: any): AxiosPromise<GameGameResponse> {
            return localVarFp.apiGameIdPut(id, score, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GamesApi - object-oriented interface
 * @export
 * @class GamesApi
 * @extends {BaseAPI}
 */
export class GamesApi extends BaseAPI {
    /**
     * получить всех игр текущего турнира.
     * @summary получить список всех игр текущего турнира.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public apiGameGet(options?: AxiosRequestConfig) {
        return GamesApiFp(this.configuration).apiGameGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить гейм по id.
     * @summary получить гейм по id.
     * @param {string} id Идентификатор гейма
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public apiGameIdGet(id: string, options?: AxiosRequestConfig) {
        return GamesApiFp(this.configuration).apiGameIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * установить счет матча.
     * @summary установить счет матча.
     * @param {string} id Идентификатор гейма
     * @param {GameSetGameScoreRequest} score Счет игра
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public apiGameIdPut(id: string, score: GameSetGameScoreRequest, options?: AxiosRequestConfig) {
        return GamesApiFp(this.configuration).apiGameIdPut(id, score, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить всех групп текущего турнира.
         * @summary получить список всех групп текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * получить всех групп текущего турнира.
         * @summary получить список всех групп текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsApiFp(configuration)
    return {
        /**
         * получить всех групп текущего турнира.
         * @summary получить список всех групп текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGet(options?: any): AxiosPromise<Array<GroupGroupResponse>> {
            return localVarFp.apiGroupGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     * получить всех групп текущего турнира.
     * @summary получить список всех групп текущего турнира.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public apiGroupGet(options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).apiGroupGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlayersApi - axios parameter creator
 * @export
 */
export const PlayersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех игроков.
         * @summary получить список всех игроков.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/player`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить игрока.
         * @summary удалить игрока.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPlayerIdDelete', 'id', id)
            const localVarPath = `/api/player/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить игрока по id.
         * @summary получить игрока по id.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPlayerIdGet', 'id', id)
            const localVarPath = `/api/player/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление игрока.
         * @summary обновление игрока.
         * @param {string} id Идентификатор игрока
         * @param {PlayerUpdatePlayerRequest} user Данные для обновления игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerIdPut: async (id: string, user: PlayerUpdatePlayerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPlayerIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiPlayerIdPut', 'user', user)
            const localVarPath = `/api/player/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание игрока.
         * @summary создание игрока.
         * @param {PlayerCreatePlayerRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerPost: async (user: PlayerCreatePlayerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiPlayerPost', 'user', user)
            const localVarPath = `/api/player`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayersApi - functional programming interface
 * @export
 */
export const PlayersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlayersApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех игроков.
         * @summary получить список всех игроков.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlayerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlayerPlayerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlayerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить игрока.
         * @summary удалить игрока.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlayerIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerPlayerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlayerIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить игрока по id.
         * @summary получить игрока по id.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlayerIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerPlayerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlayerIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление игрока.
         * @summary обновление игрока.
         * @param {string} id Идентификатор игрока
         * @param {PlayerUpdatePlayerRequest} user Данные для обновления игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlayerIdPut(id: string, user: PlayerUpdatePlayerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerPlayerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlayerIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание игрока.
         * @summary создание игрока.
         * @param {PlayerCreatePlayerRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlayerPost(user: PlayerCreatePlayerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerPlayerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlayerPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlayersApi - factory interface
 * @export
 */
export const PlayersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlayersApiFp(configuration)
    return {
        /**
         * получить список всех игроков.
         * @summary получить список всех игроков.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerGet(options?: any): AxiosPromise<Array<PlayerPlayerResponse>> {
            return localVarFp.apiPlayerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить игрока.
         * @summary удалить игрока.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerIdDelete(id: string, options?: any): AxiosPromise<PlayerPlayerResponse> {
            return localVarFp.apiPlayerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить игрока по id.
         * @summary получить игрока по id.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerIdGet(id: string, options?: any): AxiosPromise<PlayerPlayerResponse> {
            return localVarFp.apiPlayerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление игрока.
         * @summary обновление игрока.
         * @param {string} id Идентификатор игрока
         * @param {PlayerUpdatePlayerRequest} user Данные для обновления игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerIdPut(id: string, user: PlayerUpdatePlayerRequest, options?: any): AxiosPromise<PlayerPlayerResponse> {
            return localVarFp.apiPlayerIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание игрока.
         * @summary создание игрока.
         * @param {PlayerCreatePlayerRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlayerPost(user: PlayerCreatePlayerRequest, options?: any): AxiosPromise<PlayerPlayerResponse> {
            return localVarFp.apiPlayerPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayersApi - object-oriented interface
 * @export
 * @class PlayersApi
 * @extends {BaseAPI}
 */
export class PlayersApi extends BaseAPI {
    /**
     * получить список всех игроков.
     * @summary получить список всех игроков.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayersApi
     */
    public apiPlayerGet(options?: AxiosRequestConfig) {
        return PlayersApiFp(this.configuration).apiPlayerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить игрока.
     * @summary удалить игрока.
     * @param {string} id Идентификатор игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayersApi
     */
    public apiPlayerIdDelete(id: string, options?: AxiosRequestConfig) {
        return PlayersApiFp(this.configuration).apiPlayerIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить игрока по id.
     * @summary получить игрока по id.
     * @param {string} id Идентификатор игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayersApi
     */
    public apiPlayerIdGet(id: string, options?: AxiosRequestConfig) {
        return PlayersApiFp(this.configuration).apiPlayerIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление игрока.
     * @summary обновление игрока.
     * @param {string} id Идентификатор игрока
     * @param {PlayerUpdatePlayerRequest} user Данные для обновления игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayersApi
     */
    public apiPlayerIdPut(id: string, user: PlayerUpdatePlayerRequest, options?: AxiosRequestConfig) {
        return PlayersApiFp(this.configuration).apiPlayerIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание игрока.
     * @summary создание игрока.
     * @param {PlayerCreatePlayerRequest} user Данные игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayersApi
     */
    public apiPlayerPost(user: PlayerCreatePlayerRequest, options?: AxiosRequestConfig) {
        return PlayersApiFp(this.configuration).apiPlayerPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TablesApi - axios parameter creator
 * @export
 */
export const TablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить таблицу результатов текущего турнира.
         * @summary получить таблицу результатов текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTablesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TablesApi - functional programming interface
 * @export
 */
export const TablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TablesApiAxiosParamCreator(configuration)
    return {
        /**
         * получить таблицу результатов текущего турнира.
         * @summary получить таблицу результатов текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTablesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TablesTableResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTablesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TablesApi - factory interface
 * @export
 */
export const TablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TablesApiFp(configuration)
    return {
        /**
         * получить таблицу результатов текущего турнира.
         * @summary получить таблицу результатов текущего турнира.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTablesGet(options?: any): AxiosPromise<Array<TablesTableResponse>> {
            return localVarFp.apiTablesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TablesApi - object-oriented interface
 * @export
 * @class TablesApi
 * @extends {BaseAPI}
 */
export class TablesApi extends BaseAPI {
    /**
     * получить таблицу результатов текущего турнира.
     * @summary получить таблицу результатов текущего турнира.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TablesApi
     */
    public apiTablesGet(options?: AxiosRequestConfig) {
        return TablesApiFp(this.configuration).apiTablesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TournamentsApi - axios parameter creator
 * @export
 */
export const TournamentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * добавление игрока в турнир по ходу проведения.
         * @summary добавление игрока в турнир по ходу проведения.
         * @param {string} id Идентификатор турнира
         * @param {TournamentAddPlayerToTournamentRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentAddPlayerIdPut: async (id: string, user: TournamentAddPlayerToTournamentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentAddPlayerIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiTournamentAddPlayerIdPut', 'user', user)
            const localVarPath = `/api/tournament/add-player/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * список игроков не участвующих в чемпионате.
         * @summary список игроков не участвующих в чемпионате.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentFreePlayersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentFreePlayersIdGet', 'id', id)
            const localVarPath = `/api/tournament/free-players/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список всех чемпионатов.
         * @summary получить список всех чемпионатов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tournament`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить чемпионат.
         * @summary удалить чемпионат.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentIdDelete', 'id', id)
            const localVarPath = `/api/tournament/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить чемпионат по id.
         * @summary получить чемпионат по id.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentIdGet', 'id', id)
            const localVarPath = `/api/tournament/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление чемпионата.
         * @summary обновление чемпионата.
         * @param {string} id Идентификатор чемпионата
         * @param {TournamentUpdateTournamentRequest} user Данные для обновления игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentIdPut: async (id: string, user: TournamentUpdateTournamentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTournamentIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiTournamentIdPut', 'user', user)
            const localVarPath = `/api/tournament/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание чемпионата.
         * @summary создание чемпионата.
         * @param {TournamentCreateTournamentRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentPost: async (user: TournamentCreateTournamentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiTournamentPost', 'user', user)
            const localVarPath = `/api/tournament`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TournamentsApi - functional programming interface
 * @export
 */
export const TournamentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TournamentsApiAxiosParamCreator(configuration)
    return {
        /**
         * добавление игрока в турнир по ходу проведения.
         * @summary добавление игрока в турнир по ходу проведения.
         * @param {string} id Идентификатор турнира
         * @param {TournamentAddPlayerToTournamentRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentAddPlayerIdPut(id: string, user: TournamentAddPlayerToTournamentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TournamentTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentAddPlayerIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * список игроков не участвующих в чемпионате.
         * @summary список игроков не участвующих в чемпионате.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentFreePlayersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlayerPlayerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentFreePlayersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список всех чемпионатов.
         * @summary получить список всех чемпионатов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TournamentTournamentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить чемпионат.
         * @summary удалить чемпионат.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TournamentTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить чемпионат по id.
         * @summary получить чемпионат по id.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TournamentTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление чемпионата.
         * @summary обновление чемпионата.
         * @param {string} id Идентификатор чемпионата
         * @param {TournamentUpdateTournamentRequest} user Данные для обновления игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentIdPut(id: string, user: TournamentUpdateTournamentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TournamentTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание чемпионата.
         * @summary создание чемпионата.
         * @param {TournamentCreateTournamentRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTournamentPost(user: TournamentCreateTournamentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TournamentTournamentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTournamentPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TournamentsApi - factory interface
 * @export
 */
export const TournamentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TournamentsApiFp(configuration)
    return {
        /**
         * добавление игрока в турнир по ходу проведения.
         * @summary добавление игрока в турнир по ходу проведения.
         * @param {string} id Идентификатор турнира
         * @param {TournamentAddPlayerToTournamentRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentAddPlayerIdPut(id: string, user: TournamentAddPlayerToTournamentRequest, options?: any): AxiosPromise<TournamentTournamentResponse> {
            return localVarFp.apiTournamentAddPlayerIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * список игроков не участвующих в чемпионате.
         * @summary список игроков не участвующих в чемпионате.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentFreePlayersIdGet(id: string, options?: any): AxiosPromise<Array<PlayerPlayerResponse>> {
            return localVarFp.apiTournamentFreePlayersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список всех чемпионатов.
         * @summary получить список всех чемпионатов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentGet(options?: any): AxiosPromise<Array<TournamentTournamentResponse>> {
            return localVarFp.apiTournamentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить чемпионат.
         * @summary удалить чемпионат.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentIdDelete(id: string, options?: any): AxiosPromise<TournamentTournamentResponse> {
            return localVarFp.apiTournamentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить чемпионат по id.
         * @summary получить чемпионат по id.
         * @param {string} id Идентификатор игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentIdGet(id: string, options?: any): AxiosPromise<TournamentTournamentResponse> {
            return localVarFp.apiTournamentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление чемпионата.
         * @summary обновление чемпионата.
         * @param {string} id Идентификатор чемпионата
         * @param {TournamentUpdateTournamentRequest} user Данные для обновления игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentIdPut(id: string, user: TournamentUpdateTournamentRequest, options?: any): AxiosPromise<TournamentTournamentResponse> {
            return localVarFp.apiTournamentIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание чемпионата.
         * @summary создание чемпионата.
         * @param {TournamentCreateTournamentRequest} user Данные игрока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTournamentPost(user: TournamentCreateTournamentRequest, options?: any): AxiosPromise<TournamentTournamentResponse> {
            return localVarFp.apiTournamentPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TournamentsApi - object-oriented interface
 * @export
 * @class TournamentsApi
 * @extends {BaseAPI}
 */
export class TournamentsApi extends BaseAPI {
    /**
     * добавление игрока в турнир по ходу проведения.
     * @summary добавление игрока в турнир по ходу проведения.
     * @param {string} id Идентификатор турнира
     * @param {TournamentAddPlayerToTournamentRequest} user Данные игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public apiTournamentAddPlayerIdPut(id: string, user: TournamentAddPlayerToTournamentRequest, options?: AxiosRequestConfig) {
        return TournamentsApiFp(this.configuration).apiTournamentAddPlayerIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * список игроков не участвующих в чемпионате.
     * @summary список игроков не участвующих в чемпионате.
     * @param {string} id Идентификатор игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public apiTournamentFreePlayersIdGet(id: string, options?: AxiosRequestConfig) {
        return TournamentsApiFp(this.configuration).apiTournamentFreePlayersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список всех чемпионатов.
     * @summary получить список всех чемпионатов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public apiTournamentGet(options?: AxiosRequestConfig) {
        return TournamentsApiFp(this.configuration).apiTournamentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить чемпионат.
     * @summary удалить чемпионат.
     * @param {string} id Идентификатор игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public apiTournamentIdDelete(id: string, options?: AxiosRequestConfig) {
        return TournamentsApiFp(this.configuration).apiTournamentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить чемпионат по id.
     * @summary получить чемпионат по id.
     * @param {string} id Идентификатор игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public apiTournamentIdGet(id: string, options?: AxiosRequestConfig) {
        return TournamentsApiFp(this.configuration).apiTournamentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление чемпионата.
     * @summary обновление чемпионата.
     * @param {string} id Идентификатор чемпионата
     * @param {TournamentUpdateTournamentRequest} user Данные для обновления игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public apiTournamentIdPut(id: string, user: TournamentUpdateTournamentRequest, options?: AxiosRequestConfig) {
        return TournamentsApiFp(this.configuration).apiTournamentIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание чемпионата.
     * @summary создание чемпионата.
     * @param {TournamentCreateTournamentRequest} user Данные игрока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TournamentsApi
     */
    public apiTournamentPost(user: TournamentCreateTournamentRequest, options?: AxiosRequestConfig) {
        return TournamentsApiFp(this.configuration).apiTournamentPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


