import { Menu2 } from "tabler-icons-react";
import { GameList } from "../games/list"
import { TablesPage } from "../tables"

export const MainPage = () => {
    return (<div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap:"wrap"}}>
                    <img src="/img/logo.png" style={{ width: "100px" }} />
                    <div style={{display:"flex", flexDirection: "column", alignItems:"center", }}>
                        <h3 className="text-center">Открытый чемпионат Республики Адыгея по теннису 🎾</h3>
                    </div>
                </div>
                
                <TablesPage showMenu={false} />

                <GameList showMenu={false}/>
            </div>
        </div>
    </div>)
}