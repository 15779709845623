import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Player } from "../../../../domain/player/player"
import { ChevronRight, Menu2, Trash } from "tabler-icons-react"

export const TournamentAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [notPickedPlayers, setNotPickedPlayers] = useState<Player[]>([])
    const [pickedPlayers, setPickedPlayers] = useState<Player[]>([])

    let useCases = useContext(UseCasesContext)
    const getNextMonday = () => {
        let date = new Date()
        while (date.getDay() != 1) {
            date.setDate(date.getDate() + 1)
        }
        return date
    }
    const [form, setForm] = useState<{
        name: string,
        startDate: Date,
        players: Player[],
    }>({
        name: "",
        startDate: getNextMonday(),
        players: [],
    })



    const submit = () => {

        if (form.name !== "") {

            if (id) {
                useCases?.tournamentUseCase.UpdateTournament(id!, form.name!, new Date(form.startDate!), () => { navigate(-1) })
            } else {
                try {
                    let ids: string[] = []
                    pickedPlayers.forEach((player) => {
                        ids.push(player.Id())
                    })
                    useCases?.tournamentUseCase.CreateTournament(form.name!, new Date(form.startDate!), ids, () => { navigate(-1) })
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readTournamentById = async (id: UniqueId) => {
        let response = await useCases?.tournamentUseCase.ReadTournamentById(id)
        if (response instanceof Error) {

        } else {
            setForm({
                name: response!.Name(),
                startDate: response!.StartDate(),
                players: [],
            })
        }
    }

    const players = useTypedSelector(({ player }) => {
        return player
    })

    const pickPlayer = (player: Player) => {
        let notPicked: Player[] = []

        notPickedPlayers.forEach((playerItem) => {
            if (playerItem !== player) {
                notPicked.push(playerItem)
            }
        })

        setNotPickedPlayers([...notPicked])
        setPickedPlayers([...pickedPlayers, player])
    }

    const deletePlayer = (player: Player) => {
        let picked: Player[] = []

        pickedPlayers.forEach((playerItem) => {
            if (playerItem !== player) {
                picked.push(playerItem)
            }
        })

        setNotPickedPlayers([...notPickedPlayers, player])
        setPickedPlayers([...picked])
    }

    useEffect(() => {
        useCases?.playerUseCase.ReadPlayers()
    }, [])

    useEffect(() => {
        if (players?.players) {
            setNotPickedPlayers([...players!.players!])
        }
    }, [players?.players])

    useEffect(() => {
        if (id) {
            readTournamentById(id)
        }
    }, [id])

    const levels = new Map<string, string>([
        ["junior", "Новичок"],
        ["middle", "Средний"],
        ["senior", "Топ"],
    ])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                                <a className="nav-item nav-link px-0 me-xl-4" onClick={(e) => { e.preventDefault(); document.querySelector('html')?.classList.add('layout-menu-expanded') }}>
                                    <i className="ti ti-menu-2 ti-sm"></i>
                                    <Menu2 />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Турниры /</span> Добавить турнир</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление турнира</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "name",
                                            title: "Название турнира",
                                            placeholder: "Введите название турнира",
                                            value: form.name,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "startDate",
                                            title: "Дата начала",
                                            placeholder: "Введите дату начала",
                                            value: form.startDate,
                                            type: FiledType.Date,
                                        },

                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={""}
                                    updateForm={updateForm}
                                />
                                <h5 className="mb-5">Список участников</h5>
                                <div className="row">
                                    <div className="column col-md-6 mb-4">
                                        <span>Cписок незаявленных игроков</span>
                                        {notPickedPlayers.map((player) => {
                                            return <div className="card" style={{ marginTop: "10px" }}>
                                                <div className="card-body">
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="content-left">
                                                            <div className="d-flex align-items-center my-1">
                                                                <h5 className="mb-0 me-2">{player.Name() + " " + player.Surname()}</h5>
                                                                <span className={player.Level() === "senior" ? "badge bg-label-primary" : player.Level() === "middle" ? "badge bg-label-warning" : "badge bg-label-danger"}>{levels.get(player.Level())}</span>
                                                            </div>
                                                        </div>
                                                        <span className="badge bg-label-primary rounded p-2" onClick={() => { pickPlayer(player) }}>
                                                            <ChevronRight />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className="column col-md-6 mb-4">
                                        <span style={{ marginBottom: "4px" }}>Участники чемпионата</span>
                                        {pickedPlayers.map((player) => {
                                            return <div className="card" style={{ marginTop: "10px" }}>
                                                <div className="card-body">
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="content-left">
                                                            <div className="d-flex align-items-center my-1">
                                                                <h5 className="mb-0 me-2">{player.Name() + " " + player.Surname()}</h5>
                                                                <span className={player.Level() === "senior" ? "badge bg-label-primary" : player.Level() === "middle" ? "badge bg-label-warning" : "badge bg-label-danger"}>{levels.get(player.Level())}</span>
                                                            </div>
                                                        </div>
                                                        <span className="badge bg-label-danger rounded p-2" onClick={() => { deletePlayer(player) }}>
                                                            <Trash />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end" style={{ marginBottom: "30px", marginRight: "20px" }} onClick={(e) => { e.preventDefault(); submit() }}>
                                <button className="btn btn-primary d-grid">Сохранить</button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
