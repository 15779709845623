import axios from "axios";
import { Configuration, TournamentsApi } from "../generated";
import { TournamentInterface } from "../../../useCase/tournament/adapters/api/Interface";
import { Tournament } from "../../../domain/tournament/tournament";
import { Player } from "../../../domain/player/player";

export class TournamentsRepository implements TournamentInterface {
    private service: TournamentsApi;

    constructor() {
        this.service = new TournamentsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadTournaments(): Promise<Tournament[] | Error> {
        try {
            let response = await this.service.apiTournamentGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let tournaments: Tournament[] = []
                response.data.forEach(tournamentData => {
                    let startDate = new Date(tournamentData.startDate!)
                    let createdAt = new Date(tournamentData.createdAt!)
                    let modifiedAt = new Date(tournamentData.modifiedAt!)
                    let tournament = new Tournament(tournamentData.id!, tournamentData.name!, startDate, createdAt, modifiedAt)
                    tournaments.push(tournament)
                });
                return tournaments
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadTournamentById(id: UniqueId): Promise<Tournament | Error> {
        try {
            let response = await this.service.apiTournamentIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let startDate = new Date(response.data.startDate!)
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let tournament = new Tournament(response.data.id!, response.data.name!, startDate, createdAt, modifiedAt)
                return tournament
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadFreePlayersOfTournament(id: UniqueId): Promise<Player[] | Error> {
        try {
            let response = await this.service.apiTournamentFreePlayersIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })
            
            if (response.status === 200) {
                let players: Player[] = []
                response.data.forEach(playerData => {
                    let createdAt = new Date(playerData.createdAt!)
                    let modifiedAt = new Date(playerData.modifiedAt!)
                    let player = new Player(playerData.id!, playerData.name!, playerData.surname!, playerData.level!, createdAt, modifiedAt)
                    players.push(player)
                });
                return players
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async AddPlayerToTournament(tournamentId: UniqueId, playerId: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiTournamentAddPlayerIdPut(tournamentId, {playerId: playerId}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateTournament(name: string, startDate: Date, players: UniqueId[]): Promise<Tournament | Error> {
        try {
            let response = await this.service.apiTournamentPost({ name: name, startDate: startDate.toISOString(), players: players }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let startDate = new Date(response.data.startDate!)
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let tournament = new Tournament(response.data.id!, response.data.name!, startDate, createdAt, modifiedAt)
                return tournament
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async UpdateTournament(id: UniqueId, name: string, startDate: Date): Promise<Tournament | Error> {
        try {
            let response = await this.service.apiTournamentIdPut(id, { name: name, startDate: startDate.toISOString() }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let startDate = new Date(response.data.startDate!)
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let tournament = new Tournament(response.data.id!, response.data.name!, startDate, createdAt, modifiedAt)
                return tournament
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteTournament(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiTournamentIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}