import axios from "axios";
import { Configuration, PlayersApi } from "../generated";
import { PlayerInterface } from "../../../useCase/player/adapters/api/Interface";
import { Player } from "../../../domain/player/player";

export class PlayersRepository implements PlayerInterface {
    private service: PlayersApi;

    constructor () {
        this.service = new PlayersApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadPlayers(): Promise<Player[] | Error> {
        try {
            let response = await this.service.apiPlayerGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let players:Player[] = []
                response.data.forEach(playerData => {
                    let createdAt = new Date(playerData.createdAt!)
                    let modifiedAt = new Date(playerData.modifiedAt!)
                    let player = new Player(playerData.id!, playerData.name!, playerData.surname!, playerData.level!, createdAt, modifiedAt)
                    players.push(player)
                });
               return players
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadPlayerById(id: UniqueId): Promise<Player | Error> {
        try {
            let response = await this.service.apiPlayerIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let player = new Player(response.data.id!, response.data.name!, response.data.surname!, response.data.level!, createdAt, modifiedAt)
                return player
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreatePlayer(name: string, surname: string, level: string): Promise<Player | Error> {
        try {
            let response = await this.service.apiPlayerPost({name: name, surname: surname, level: level}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let player = new Player(response.data.id!, response.data.name!, response.data.surname!, response.data.level!, createdAt, modifiedAt)
                return player
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdatePlayer(id: UniqueId, name: string, surname: string, level: string): Promise<Player | Error> {
        try {
            let response = await this.service.apiPlayerIdPut(id, {name: name, surname: surname, level: level}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)
                let player = new Player(response.data.id!, response.data.name!, response.data.surname!, response.data.level!, createdAt, modifiedAt)
                return player
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeletePlayer(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiPlayerIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
}