import { Dispatch } from "react"
import { GameActionTypes } from "../action-types";
import { GameActions } from "../actions"
import { Game } from "../../domain/game/game";

export const SendGameListRequest = () => {
    return async (dispatch: Dispatch<GameActions>) => {
        dispatch({
            type: GameActionTypes.GAME_REQUEST_SEND,
          });
    }
}

export const GameListSuccess = (kvizes: Game[]) => {
    return async (dispatch: Dispatch<GameActions>) => {
        dispatch({
            type: GameActionTypes.GAME_SUCCESS,
            payload: kvizes,
        });
    }
}

export const GameListError = (error:string) => {
    return async (dispatch: Dispatch<GameActions>) => {
        dispatch({
            type: GameActionTypes.GAME_ERROR,
            payload: error,
        });
    }
}


export const GameUpdateRequest = () => {
    return async (dispatch: Dispatch<GameActions>) => {
        dispatch({
            type: GameActionTypes.GAME_UPDATE_REQUEST,
          });
    }
}

export const GameUpdateSuccess = (category: Game) => {
    return async (dispatch: Dispatch<GameActions>) => {
        dispatch({
            type: GameActionTypes.GAME_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const GameUpdateError = (message: string) => {
    return async (dispatch: Dispatch<GameActions>) => {
        dispatch({
            type: GameActionTypes.GAME_UPDATE_ERROR,
            payload: message,
          });
    }
}