import { Dispatch } from "react"
import { PlayerActionTypes } from "../action-types";
import { PlayerActions } from "../actions"
import { Player } from "../../domain/player/player";

export const SendPlayerListRequest = () => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_REQUEST_SEND,
          });
    }
}

export const PlayerListSuccess = (kvizes: Player[]) => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_SUCCESS,
            payload: kvizes,
        });
    }
}

export const PlayerListError = (error:string) => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_ERROR,
            payload: error,
        });
    }
}

export const PlayerCreateRequest = () => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_CREATE_REQUEST,
          });
    }
}

export const PlayerCreateSuccess = (kviz: Player) => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_CREATE_SUCCESS,
            payload: kviz,
          });
    }
}

export const PlayerCreateError = (message: string) => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_CREATE_ERROR,
            payload: message,
          });
    }
}


export const PlayerUpdateRequest = () => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_UPDATE_REQUEST,
          });
    }
}

export const PlayerUpdateSuccess = (category: Player) => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const PlayerUpdateError = (message: string) => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const PlayerDeleteRequest = () => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_DELETE_REQUEST,
          });
    }
}

export const PlayerDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const PlayerDeleteError = (message: string) => {
    return async (dispatch: Dispatch<PlayerActions>) => {
        dispatch({
            type: PlayerActionTypes.PLAYER_DELETE_ERROR,
            payload: message,
          });
    }
}
