import { useContext, useState } from "react";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { TableItem } from "../../../domain/table/table";

export const TableRow = (props: { tableItem: TableItem, last: boolean }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }

    const levels = new Map<string, string>([
        ["junior", "Новичок"],
        ["middle", "Средний"],
        ["senior", "Топ"],
    ])
    return (<tr className="d-flex align-items-center">
        <td className="col-1">{props.tableItem?.Place()}</td>
        <td className="col-4 col-lg">{props.tableItem?.Name() + " " + props.tableItem?.Surname()}</td> {/*{<span style={{fontSize: "8px", padding: "5px"}} className={props.tableItem.Level()==="senior"?"rounded-pill bg-label-primary":props.tableItem.Level()==="middle"?"rounded-pill bg-label-warning":"rounded-pill bg-label-danger"}>{levels.get(props.tableItem.Level())}</span>} */}
        <td className="col-1">{props.tableItem?.PlayedGames()}</td>
        <td className="col-1" >{props.tableItem?.Wins()}</td>
        <td className="col-1" >{props.tableItem?.Loses()}</td>
        <td className="col-1" >{props.tableItem?.Points()}</td>
    </tr>)
}
