import axios from "axios";
import { Configuration, TablesApi } from "../generated";
import { Table, TableItem } from "../../../domain/table/table";
import { TableInterface } from "../../../useCase/table/adapters/api/Interface";

export class TablesRepository implements TableInterface {
    private service: TablesApi;

    constructor () {
        this.service = new TablesApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async  ReadTablesOfLastTournament(): Promise<Table[] | Error> {
        try {
            let response = await this.service.apiTablesGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let tables:Table[] = []
                response.data.forEach(tableData => {
                    let tableItems: TableItem[] = [];
                    console.log(tableData?.items)
                    tableData?.items?.forEach(item => {
                        let tableItem = new TableItem(item.player_id!, item.place!, item.name!, item.surname!, item.level!, item.playedGames!, item.wins!, item.loses!, item.points!);
                        tableItems.push(tableItem)
                    });
                    
                    let table = new Table(tableData.tournament_id!, tableData.tournament_name!, tableData.group_id!, tableData.group_name!, tableItems!)
                    tables.push(table)
                });
               return tables
            }
        } catch(e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
}