import { createContext } from 'react'
import { AuthorizationUseCases } from '../useCase/authorization/authorization';
import { PlayerUseCases } from '../useCase/player/player';
import { TournamentUseCases } from '../useCase/tournament/tournament';
import { GameUseCases } from '../useCase/game/game';
import { GroupUseCases } from '../useCase/group/group';
import { TableUseCases } from '../useCase/table/table';

interface UseCasesContextInterface {
    authUseCase: AuthorizationUseCases;
    playerUseCase: PlayerUseCases;
    tournamentUseCase: TournamentUseCases;
    gameUseCase: GameUseCases;
    groupUseCase: GroupUseCases;
    tableUseCase: TableUseCases;
}

export const UseCasesContext = createContext<UseCasesContextInterface | null>(null)
