export enum PlayerActionTypes {
    PLAYER_REQUEST_SEND = 'player_request_send',
    PLAYER_ERROR = 'player_error',
    PLAYER_SUCCESS = 'player_success',
    
    PLAYER_CREATE_REQUEST = 'player_create_request',
    PLAYER_CREATE_SUCCESS = 'player_create_success',
    PLAYER_CREATE_ERROR = 'player_create_error',

    PLAYER_DELETE_REQUEST = 'player_delete_request',
    PLAYER_DELETE_SUCCESS = 'player_delete_success',
    PLAYER_DELETE_ERROR = 'player_delete_error',

    PLAYER_UPDATE_REQUEST = 'player_update_request',
    PLAYER_UPDATE_SUCCESS = 'player_update_success',
    PLAYER_UPDATE_ERROR = 'player_update_error',
}
