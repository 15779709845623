export class Group {
    private id: UniqueId;
    private tournamentId: UniqueId;
    private name: string;
    private players: UniqueId[];
    private createdAt: Date;
    private modifiedAt: Date;

    constructor(id: UniqueId, tournamentId: UniqueId, name: string, players: UniqueId[], createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.tournamentId = tournamentId;
        this.name = name;
        this.players = players;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    public Id = (): UniqueId => {
        return this.id
    }

    public TournamentId = (): UniqueId => {
        return this.tournamentId
    }
    
    public Name = (): string => {
        return this.name
    }

    public Players = (): UniqueId[] => {
        return this.players
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }

}
