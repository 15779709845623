export class Game {
    private id: UniqueId;
    private groupId: string;
    private tour: number;
    private playerA: UniqueId;
    private playerB: UniqueId;
    private gameDay: Date;
    private score: Score; 
    private winner: UniqueId;
    private startDate: Date;
    private deadline: Date;
    private createdAt: Date;
    private modifiedAt: Date;

    constructor(id: UniqueId, groupId: UniqueId, tour: number, playerA: UniqueId, playerB: UniqueId, gameDay: Date, score: Score, winner: UniqueId, startDate: Date, deadline: Date, createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.groupId = groupId;
        this.tour = tour;
        this.playerA = playerA;
        this.playerB = playerB;
        this.gameDay = gameDay;
        this.score = score;
        this.winner = winner;
        this.startDate = startDate;
        this.deadline = deadline;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    public Id = (): UniqueId => {
        return this.id
    }

    public GroupId = (): UniqueId => {
        return this.groupId
    }
    
    public Tour = (): number => {
        return this.tour
    }

    public PlayerA = (): UniqueId => {
        return this.playerA
    }

    public PlayerB = (): UniqueId => {
        return this.playerB
    }

    public GameDay = (): Date => {
        return this.gameDay
    }

    public Score = (): Score => {
        return this.score
    }

    public Winner = (): UniqueId => {
        return this.winner
    }

    public StartDate = (): Date => {
        return this.startDate
    }

    public Deadline = (): Date => {
        return this.deadline
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }

}

export class Score {
    private sets: Set[]

    constructor(sets: Set[]) {
        this.sets = sets
    }

    public Sets = (): Set[] => {
        return this.sets
    }

    public AddSet = (set:Set) => {
        this.sets.push(set)
    }
}

export class Set {
    private playerAScore: number;
    private playerBScore: number;

    constructor(playerAScore: number, playerBScore: number) {
        this.playerAScore = playerAScore
        this.playerBScore = playerBScore
    }

    public PlayerAScore = (): number => {
        return this.playerAScore
    }

    public SetPlayerAScore = (score: number) => {
        this.playerAScore = score
    }

    public PlayerBScore = (): number => {
        return this.playerBScore
    }

    public SetPlayerBScore = (score: number) => {
        this.playerBScore = score
    }
}