import { Player } from "../../domain/player/player";
import { PlayersRepository } from "../../repository/api/player/player";
import { actionCreators } from "../../state";

export class PlayerUseCases {
    private api: PlayersRepository;
    private stateActions: typeof actionCreators;

    constructor(api: PlayersRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadPlayers = async () => {
        this.stateActions.SendPlayerListRequest()
        let response = await this.api.ReadPlayers()
        if (response instanceof Error) {
            this.stateActions.PlayerListError(response.message)
            return
        } else {
            this.stateActions.PlayerListSuccess(response)
        }
    }

    public ReadPlayerById = async (id: UniqueId): Promise< Player| Error> => {
        //this.stateActions.SendPlayerListRequest()
        let response = await this.api.ReadPlayerById(id)
        return response
    }

    public CreatePlayer = async (name: string, surname: string, level: string, callback?: ()=>void) => {
        this.stateActions.PlayerCreateRequest()
        let response = await this.api.CreatePlayer(name, surname, level)
        if (response instanceof Error) {
            this.stateActions.PlayerDeleteError(response.message)
            return
        } else {
            this.stateActions.PlayerCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdatePlayer = async (id: UniqueId, name: string, surname: string, level: string, callback?: ()=>void) => {
        this.stateActions.PlayerUpdateRequest()
        let response = await this.api.UpdatePlayer(id, name, surname, level)
        if (response instanceof Error) {
            this.stateActions.PlayerDeleteError(response.message)
            return
        } else {
            this.stateActions.PlayerUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public DeletePlayer = async (id: UniqueId) => {
        this.stateActions.PlayerDeleteRequest()
        let response = await this.api.DeletePlayer(id)
        if (response instanceof Error) {
            this.stateActions.PlayerDeleteError(response.message)
            return
        } else {
            this.stateActions.PlayerDeleteSuccess(id)
        }
    }

}