import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { Game, Score, Set } from "../../../../domain/game/game"
import { Player } from "../../../../domain/player/player"
import { Menu2, Minus, Trash } from "tabler-icons-react"

export const GameScore = () => {
    const navigate = useNavigate();
    const [game, setGame] = useState<Game>()
    const [playerA, setPlayerA] = useState<Player>()
    const [playerB, setPlayerB] = useState<Player>()
    const [sets, setSets] = useState<Set[]>([])
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        date: Date,
        score: Set[],
    }>({
        date: new Date(),
        score: sets,
    })

    const submit = () => {
        if (id && sets!.length > 1) {
            let score = new Score(sets)
            useCases?.gameUseCase.UpdateGame(id!, form.date!, score, () => { navigate(-1) })
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readGameById = async (id: UniqueId) => {
        let response = await useCases?.gameUseCase.ReadGameById(id)
        if (response instanceof Error) {

        } else {
            setGame(response)
        }
    }

    const readPlayers = async () => {
        let playerA = await useCases?.playerUseCase.ReadPlayerById(game!.PlayerA())
        if (playerA instanceof Player) {
            setPlayerA(playerA)
        }

        let playerB = await useCases?.playerUseCase.ReadPlayerById(game!.PlayerB())
        if (playerB instanceof Player) {
            setPlayerB(playerB)
        }
    }

    const addSet = () => {
        setSets([...sets, new Set(0,0)])
    }

    const deleteSet = (index:number) => {
        let newSets = sets
        newSets.splice(index, 1)
        setSets([...newSets])
    }

    const updateScore = (index: number, score: number, first: boolean) => {
        let newSets = sets
        if (first) {
            newSets[index].SetPlayerAScore(score)
        } else {
            newSets[index].SetPlayerBScore(score)
        }
        setSets([...newSets])
    }

    useEffect(() => {
        if (!game) {
            return
        }
        readPlayers()
        
    }, [game])

    useEffect(() => {
        console.log(form)
    }, [form])

    useEffect(() => {
        if (id) {
            readGameById(id)

        }
    }, [id])

    const levels = new Map<string, string>([
        ["junior", "Новичок"],
        ["middle", "Средний"],
        ["senior", "Топ"],
    ])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                <a className="nav-item nav-link px-0 me-xl-4" onClick={(e)=> {e.preventDefault(); document.querySelector('html')?.classList.add('layout-menu-expanded')}}>
                    <i className="ti ti-menu-2 ti-sm"></i>
                    <Menu2 />
                </a>
            </div>
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Счет /</span> Установить счет матча</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Установить счет матча</h5>
                            </div>
                            <div className="card-body">
                                <div className="row justify-content-md-center">
                                    {playerA ?
                                        <div className="mb-3 col-md-4 fv-plugins-icon-container">
                                            <div className="content-right">
                                                <div className="d-flex align-items-center my-1">
                                                    <h5 className="mb-0 me-2">{playerA.Name() + " " + playerA.Surname()}</h5>
                                                    <span className={playerA.Level() === "senior" ? "badge bg-label-primary" : playerA.Level() === "middle" ? "badge bg-label-warning" : "badge bg-label-danger"}>{levels.get(playerA.Level())}</span>
                                                </div>
                                            </div>
                                        </div> : <></>}
                                    {playerB ?
                                        <div className="mb-3 col-md-4 fv-plugins-icon-container">
                                            <div className="content-right">
                                                <div className="d-flex justify-end align-items-end my-1" style={{justifyContent:"end"}}>
                                                    <h5 className="mb-0 me-2">{playerB.Name() + " " + playerB.Surname()}</h5>
                                                    <span className={playerB.Level() === "senior" ? "badge bg-label-primary" : playerB.Level() === "middle" ? "badge bg-label-warning" : "badge bg-label-danger"}>{levels.get(playerB.Level())}</span>
                                                </div>
                                            </div>
                                        </div> : <></>}
                                </div>
                                <div className="row justify-content-md-center">
                                    {sets && sets!.map((set, index)=>{
                                        return <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                                                <h6>Результат {index+1}-го сета</h6>
                                                <div style={{display: "flex", alignItems: "center", flexDirection: "row", marginBottom: "10px"}}>
                                                    <div style={{maxWidth:"100px"}}>
                                                        <input type="number" className="form-control" name="name" placeholder={playerA!.Name()+" "+playerA!.Surname()} onChange={(e)=>{updateScore(index, parseInt(e.target.value), true)}} value={set.PlayerAScore()} />
                                                    </div>
                                                    <span className="badge bg-label-primary rounded p-2" style={{marginLeft:"10px", marginRight:"10px"}}>
                                                        <Minus size={15} />
                                                    </span>
                                                    <div style={{maxWidth:"100px"}}>
                                                        <input type="number" className="form-control" name="name" placeholder={playerB!.Name()+" "+playerB!.Surname()} onChange={(e)=>{updateScore(index, parseInt(e.target.value), false)}} value={set.PlayerBScore()} />
                                                    </div>
                                                    <span className="badge bg-label-danger rounded p-2" style={{ marginLeft:"10px", marginRight:"10px", cursor: "pointer"}} onClick={(e) => {e.preventDefault(); deleteSet(index)}}>
                                                        <Trash size={15} />
                                                    </span>
                                                </div>
                                        </div>
                                    })}
                                </div>
                                {sets.length < 5 ?<div className="d-flex justify-content-center" style={{marginBottom: "30px", marginTop: "30px"}} onClick={(e) => {e.preventDefault(); addSet()}}>
                                    <button className="btn btn-primary d-grid">+ Добавить сет</button>
                                </div>:<></>}
                                <div className="d-flex justify-content-end" style={{marginBottom: "30px", marginRight:"20px"}} onClick={(e) => {e.preventDefault(); submit()}}>
                                    <button className="btn btn-primary d-grid">Сохранить</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
