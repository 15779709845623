import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { Menu2 } from "tabler-icons-react"

export const PlayerAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        name: string,
        surname: string,
        level: string,
    }>({
        name: "",
        surname: "",
        level: "",
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (form.name !== "") {
            if (id) {
                useCases?.playerUseCase.UpdatePlayer(id!, form.name!, form.surname, form.level, ()=>{navigate(-1)})
            } else {
                useCases?.playerUseCase.CreatePlayer(form.name!, form.surname, form.level, ()=>{navigate(-1)})
            }
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readPlayerById = async (id: UniqueId) => {
        let response = await useCases?.playerUseCase.ReadPlayerById(id)
        if (response instanceof Error) {

        } else {
            setForm({
                name: response!.Name(),
                surname: response!.Surname(),
                level: response!.Level(),
            })
        }
    }

    useEffect(()=>{
        console.log(form)
    },[form])

    useEffect(()=>{
        if (id) {
            readPlayerById(id)
        }
    },[id])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                            <a className="nav-item nav-link px-0 me-xl-4" onClick={(e)=> {e.preventDefault(); document.querySelector('html')?.classList.add('layout-menu-expanded')}}>
                                <i className="ti ti-menu-2 ti-sm"></i>
                                <Menu2 />
                            </a>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Игрок /</span> Добавить игрока</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление игрока</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "name",
                                            title: "Имя",
                                            placeholder: "Введите имя игрока",
                                            value: form.name,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "surname",
                                            title: "Фамилия",
                                            placeholder: "Введите фамилию игрока",
                                            value: form.surname,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "level",
                                            title: "Уровень игрока",
                                            placeholder: "Выберите уровень игрока",
                                            type: FiledType.Select,
                                            value: form.level,
                                            options: [
                                                {
                                                    title: "Новичок",
                                                    value: "junior"
                                                },
                                                {
                                                    title: "Средний",
                                                    value: "middle"
                                                },
                                                {
                                                    title: "Топ",
                                                    value: "senior"
                                                },
                                            ],

                                        },
                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
