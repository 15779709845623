import { Player } from "../../domain/player/player";
import { PlayerActionTypes } from "../action-types";
import { PlayerActions } from "../actions";
import produce from 'immer';


interface PlayerState {
    loading: boolean | null;
    players: Player[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: PlayerState = {
    loading: null,
    players: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: PlayerState = initialState, action: PlayerActions) => {
    switch (action.type) {
        case PlayerActionTypes.PLAYER_REQUEST_SEND:
            state.loading = true;
            return state;
        case PlayerActionTypes.PLAYER_SUCCESS:
            state.loading = false;
            state.players = action.payload;
            return state;
        case PlayerActionTypes.PLAYER_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case PlayerActionTypes.PLAYER_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case PlayerActionTypes.PLAYER_CREATE_SUCCESS:
            state.entityLoading = false
            state.players!.push(action.payload)
            state.entityError = ""
            return state;
        case PlayerActionTypes.PLAYER_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case PlayerActionTypes.PLAYER_DELETE_SUCCESS:
            state.players = state.players!.filter((players)=>players.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
