import { Dispatch } from "react"
import { TournamentActionTypes } from "../action-types";
import { TournamentActions } from "../actions"
import { Tournament } from "../../domain/tournament/tournament";

export const SendTournamentListRequest = () => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_REQUEST_SEND,
          });
    }
}

export const TournamentListSuccess = (kvizes: Tournament[]) => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_SUCCESS,
            payload: kvizes,
        });
    }
}

export const TournamentListError = (error:string) => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_ERROR,
            payload: error,
        });
    }
}

export const TournamentCreateRequest = () => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_CREATE_REQUEST,
          });
    }
}

export const TournamentCreateSuccess = (kviz: Tournament) => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_CREATE_SUCCESS,
            payload: kviz,
          });
    }
}

export const TournamentCreateError = (message: string) => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_CREATE_ERROR,
            payload: message,
          });
    }
}


export const TournamentUpdateRequest = () => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_UPDATE_REQUEST,
          });
    }
}

export const TournamentUpdateSuccess = (category: Tournament) => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const TournamentUpdateError = (message: string) => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const TournamentDeleteRequest = () => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_DELETE_REQUEST,
          });
    }
}

export const TournamentDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const TournamentDeleteError = (message: string) => {
    return async (dispatch: Dispatch<TournamentActions>) => {
        dispatch({
            type: TournamentActionTypes.TOURNAMENT_DELETE_ERROR,
            payload: message,
          });
    }
}
