import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { Player } from "../../../../domain/player/player"
import { Group } from "../../../../domain/group/group"
import { Menu2 } from "tabler-icons-react"

export const GameList = ({showMenu}:{showMenu: boolean}) => {
    let { id } = useParams();
    let useCases = useContext(UseCasesContext)

    const [activeGroupId, setActiveGroupId] = useState<string|undefined>()

    const games = useTypedSelector(({ game }) => {
        return game
    })

    const groups = useTypedSelector(({ group }) => {
        return group
    })

    const players = useTypedSelector(({ player }) => {
        return player
    })

    useEffect(() => {
        useCases?.gameUseCase.ReadGamesOfLastTournament()
        useCases?.groupUseCase.ReadGroupsOfLastTournament()
        useCases?.playerUseCase.ReadPlayers()
    }, [])

    const pickPlayerById = (id: UniqueId): Player | null => {
        let result: Player | null = null
        players?.players?.forEach((player) => {
            if (id === player.Id()) {
                result = player
                return result
            }
        })
        return result
    }

    const pickGroupById = (id: UniqueId): Group | null => {
        let result: Group | null = null
        groups?.groups?.forEach((group) => {
            if (id === group.Id()) {
                result = group
                return result
            }
        })
        return result
    }

    return (
        <div className={showMenu?"layout-page":""} >
            {showMenu?<Menu />:<></>}
            
            <div className="content-wrapper" >
                {showMenu?<div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none" style={{marginLeft: "25px", marginTop: "10px"}}>
                    <a className="nav-item nav-link px-0 me-xl-4" onClick={(e)=> {e.preventDefault(); document.querySelector('html')?.classList.add('layout-menu-expanded')}}>
                        <i className="ti ti-menu-2 ti-sm"></i>
                        <Menu2 />
                    </a>
                </div>:<></>}
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Календарь игр /</span> Календарь игр</h4>
                        </div>
                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                            <li className="nav-item" style={{marginRight: "10px"}}>
                                <a className={activeGroupId?"nav-link":"nav-link active"} onClick={(e)=>{e.preventDefault(); setActiveGroupId(undefined)}} href="#"><i className="ti-xs ti ti-user-check me-1"></i>Все</a>
                            </li>
                            {groups?.groups?.map((group, index) => {
                                return <li className="nav-item" style={{marginRight: "10px"}}>
                                    <a className={activeGroupId===group.Id()?"nav-link active":"nav-link"} onClick={(e)=>{e.preventDefault(); setActiveGroupId(group.Id())}} href="#"><i className="ti-xs ti ti-user-check me-1"></i> {group.Name()}</a>
                                </li>
                            })}
                        </ul>

                        <div className="card">
                            <div className="table-responsive text-nowrap">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th>Группа</th>
                                            <th>Тур</th>
                                            <th>Игрок 1</th>
                                            <th>Результат</th>
                                            <th>Игрок 2</th>
                                            <th>Дата проведения</th>
                                            {showMenu?<th>Действия</th>:<></>}
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            games?.games && games.games.map((game, index) => {
                                                if (game.GroupId() === activeGroupId || activeGroupId === undefined) {
                                                    return <TableRow game={game} player1={pickPlayerById(game.PlayerA())} player2={pickPlayerById(game.PlayerB())} group={pickGroupById(game.GroupId())} last={games?.games!.length - 1 == index ? true : false} edit={showMenu} />
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}