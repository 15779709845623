import { Game, Score } from "../../domain/game/game";
import { GamesRepository } from "../../repository/api/game/game";
import { actionCreators } from "../../state";

export class GameUseCases {
    private api: GamesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: GamesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadGamesOfLastTournament = async () => {
        this.stateActions.SendGameListRequest()
        let response = await this.api.ReadGamesOfLastTournament()
        if (response instanceof Error) {
            this.stateActions.GameListError(response.message)
            return
        } else {
            this.stateActions.GameListSuccess(response)
        }
    }

    public ReadGameById = async (id: UniqueId) => {
        let response = await this.api.ReadGameById(id)
        return response
    }

    public UpdateGame = async (id: UniqueId, date: Date, score: Score, callback?: ()=>void) => {
        this.stateActions.GameUpdateRequest()
        let response = await this.api.SetScoreRequest(id, date, score)
        if (response instanceof Error) {
            this.stateActions.GameUpdateError(response.message)
            return
        } else {
            this.stateActions.GameUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}