import { Group } from "../../domain/group/group";
import { GroupsRepository } from "../../repository/api/group/group";
import { actionCreators } from "../../state";

export class GroupUseCases {
    private api: GroupsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: GroupsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadGroupsOfLastTournament = async () => {
        this.stateActions.SendGroupListRequest()
        let response = await this.api.ReadGroupsOfLastTournament()
        if (response instanceof Error) {
            this.stateActions.GroupListError(response.message)
            return
        } else {
            this.stateActions.GroupListSuccess(response)
        }
    }

}