import { BallTennis, Calendar, Logout, Table, Target, Users } from 'tabler-icons-react';

export const Items = [
    {
        title: "Турниры",
        icon: <BallTennis size={24} className="menu-icon"  />,
        href: "/tournament",
    },
    {
        title: "Игроки",
        icon: <Users size={24} className="menu-icon"  />,
        href: "/player",
    },
    {
        title: "Таблицы",
        icon: <Table size={24} className="menu-icon"  />,
        href: "/tables",
    },
    {
        title: "Календарь игр",
        icon: <Calendar size={24} className="menu-icon"  />,
        href: "/game",
    },
    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
    },
]
