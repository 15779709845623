export enum TournamentActionTypes {
    TOURNAMENT_REQUEST_SEND = 'tournament_request_send',
    TOURNAMENT_ERROR = 'tournament_error',
    TOURNAMENT_SUCCESS = 'tournament_success',
    
    TOURNAMENT_CREATE_REQUEST = 'tournament_create_request',
    TOURNAMENT_CREATE_SUCCESS = 'tournament_create_success',
    TOURNAMENT_CREATE_ERROR = 'tournament_create_error',

    TOURNAMENT_DELETE_REQUEST = 'tournament_delete_request',
    TOURNAMENT_DELETE_SUCCESS = 'tournament_delete_success',
    TOURNAMENT_DELETE_ERROR = 'tournament_delete_error',

    TOURNAMENT_UPDATE_REQUEST = 'tournament_update_request',
    TOURNAMENT_UPDATE_SUCCESS = 'tournament_update_success',
    TOURNAMENT_UPDATE_ERROR = 'tournament_update_error',
}
