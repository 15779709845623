import { Auth } from "../repository/api/auth/auth"
import { PlayersRepository } from "../repository/api/player/player"
import { TournamentsRepository } from "../repository/api/tournament/tournament"
import { AuthorizationUseCases } from "../useCase/authorization/authorization"
import { PlayerUseCases } from "../useCase/player/player"
import { GameUseCases } from "../useCase/game/game"
import { GroupUseCases } from "../useCase/group/group"
import { TournamentUseCases } from "../useCase/tournament/tournament"
import { GamesRepository } from "../repository/api/game/game"
import { GroupsRepository } from "../repository/api/group/group"
import { TableUseCases } from "../useCase/table/table"
import { TablesRepository } from "../repository/api/table/table"


export const useUseCases = (actions:any) => {
    const authUseCase = new AuthorizationUseCases(new Auth(), actions)
    const playerUseCase = new PlayerUseCases(new PlayersRepository(), actions)
    const tournamentUseCase = new TournamentUseCases(new TournamentsRepository(), actions)
    const gameUseCase = new GameUseCases(new GamesRepository(), actions)
    const groupUseCase = new GroupUseCases(new GroupsRepository(), actions)
    const tableUseCase = new TableUseCases(new TablesRepository(), actions)

    return {authUseCase, playerUseCase, tournamentUseCase, gameUseCase, groupUseCase, tableUseCase}
}
