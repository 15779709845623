import { TournamentsRepository } from "../../repository/api/tournament/tournament";
import { actionCreators } from "../../state";

export class TournamentUseCases {
    private api: TournamentsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: TournamentsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadTournaments = async () => {
        this.stateActions.SendTournamentListRequest()
        let response = await this.api.ReadTournaments()
        if (response instanceof Error) {
            this.stateActions.TournamentListError(response.message)
            return
        } else {
            this.stateActions.TournamentListSuccess(response)
        }
    }

    public ReadTournamentById = async (id: UniqueId) => {
        let response = await this.api.ReadTournamentById(id)
        return response
    }

    public CreateTournament = async (name: string, startDate: Date, playerIds: UniqueId[], callback?: ()=>void) => {
        this.stateActions.TournamentCreateRequest()
        let response = await this.api.CreateTournament(name, startDate, playerIds)
        if (response instanceof Error) {
            this.stateActions.TournamentDeleteError(response.message)
            return
        } else {
            this.stateActions.TournamentCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateTournament = async (id: UniqueId, name: string, startDate: Date, callback?: ()=>void) => {
        this.stateActions.TournamentUpdateRequest()
        let response = await this.api.UpdateTournament(id, name, startDate)
        if (response instanceof Error) {
            this.stateActions.TournamentDeleteError(response.message)
            return
        } else {
            this.stateActions.TournamentUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public DeleteTournament = async (id: UniqueId) => {
        this.stateActions.TournamentDeleteRequest()
        let response = await this.api.DeleteTournament(id)
        if (response instanceof Error) {
            this.stateActions.TournamentDeleteError(response.message)
            return
        } else {
            this.stateActions.TournamentDeleteSuccess(id)
        }
    }

    public ReadFreePlayersOfTournament = async (id: UniqueId) => {
        let response = await this.api.ReadFreePlayersOfTournament(id)
        return response
    }

    public AddPlayerToTournament = async (tournamentId: UniqueId, playerId: UniqueId, callback?: ()=>void) => {
        let response = this.api.AddPlayerToTournament(tournamentId, playerId)
        if (response instanceof Error) {
            return
        } else {
            if (callback) {
                callback()
            }
        }
    }

}