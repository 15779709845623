import { Dispatch } from "react"
import { TableActionTypes } from "../action-types";
import { TableActions } from "../actions"
import { Table } from "../../domain/table/table";

export const SendTableListRequest = () => {
    return async (dispatch: Dispatch<TableActions>) => {
        dispatch({
            type: TableActionTypes.TABLE_REQUEST_SEND,
          });
    }
}

export const TableListSuccess = (kvizes: Table[]) => {
    return async (dispatch: Dispatch<TableActions>) => {
        dispatch({
            type: TableActionTypes.TABLE_SUCCESS,
            payload: kvizes,
        });
    }
}

export const TableListError = (error:string) => {
    return async (dispatch: Dispatch<TableActions>) => {
        dispatch({
            type: TableActionTypes.TABLE_ERROR,
            payload: error,
        });
    }
}